import React from 'react';
import { Container, Typography, Button, Box, Grid, Card, CardContent, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import BoltIcon from '@mui/icons-material/Bolt';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import TinderIcon from '@mui/icons-material/Whatshot';
import OkCupidIcon from '@mui/icons-material/Favorite';
import BumbleIcon from '@mui/icons-material/LocalFlorist';
import MomoIcon from '@mui/icons-material/EmojiPeople';
import HingeIcon from '@mui/icons-material/LinkRounded';

const HeroSection = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF4081 30%, #FF9100 90%)',
  color: 'white',
  padding: theme.spacing(10, 0),
  textAlign: 'center',
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: '-webkit-linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

const TestimonialCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(3),
}));

const FAQCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  },
}));

const PlatformIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(0, 2),
}));

const LandingPage = () => {
  const navigate = useNavigate();

  const handleStartTrial = () => {
    navigate('/signup');
  };

  const handleSubscription = (planType) => {
    localStorage.setItem('selectedPlan', planType);
    navigate('/login');
  };

  const features = [
    { title: "Advanced Dating Profile Rater", description: "Upload your photos, and let our AI—trained on over 100,000 top-performing dating profiles from diverse backgrounds, cultures, and ethnicities—give you personalized feedback. Whether you're looking for love across different cultures or within your community, our AI delivers tailored insights to help you make a lasting impression. Perfect your look, boost your appeal, and attract the matches you've been waiting for, no matter where you're from", icon: <StarIcon /> },
    { title: "AI-Powered Ice Breakers", description: "Say goodbye to awkward silences and hello to instant connections. Our advanced AI crafts icebreakers so engaging, they won't just respond—they'll want to keep the conversation going. With lines tailored to the moment and designed to spark interest, your matches won't be able to resist. Stop guessing what to say and let RizzAI Guru do the work for you. Start generating more replies—and more dates—today.", icon: <BoltIcon /> },
    { title: "Smart Reply Suggestions", description: "Never get stuck on what to say next. Our AI delivers perfectly timed, personalized replies that keep your conversations engaging and effortless. Whether you're sparking chemistry or deepening a connection, our smart replies are designed to impress and maintain momentum. Say goodbye to awkward pauses and missed chances—let RizzAI Guru ensure your chats are smooth, fun, and always moving forward.", icon: <FavoriteIcon /> },
  ];

  const faqItems = [
    { 
      question: "How do I get started with RizzAI Guru?", 
      answer: "Getting started is easy! Simply sign up for an account to begin your 7-day free trial. No credit card is required for the initial sign-up."
    },
    {
      question: "How does RizzAI Guru improve my dating game?", 
      answer: "RizzAI Guru uses advanced AI to analyze successful dating interactions and provide you with personalized conversation starters, profile optimization tips, and smart reply suggestions. It's like having a dating coach in your pocket!"
    },
    { 
      question: "Is my data safe with RizzAI Guru?", 
      answer: "Absolutely! We take your privacy seriously. All your data is encrypted and never shared with third parties. We only use your information to improve your personal experience with the service."
    },
    { 
      question: "Can I use RizzAI Guru with multiple dating apps?", 
      answer: "Yes! RizzAI Guru is designed to work with all major dating platforms, including Tinder, Bumble, Hinge, and more. You can seamlessly integrate RizzAI Guru with your favorite dating apps."
    },
    {
      question: "What happens after my free trial ends?", 
      answer: "After your 7-day free trial, you can choose to subscribe to one of our plans to continue using RizzAI Guru. We offer flexible monthly and yearly plans to suit your needs."
    },
    {
      question: "Can I cancel my subscription at any time?", 
      answer: "Yes, you can cancel your subscription at any time. If you cancel, you'll still have access to RizzAI Guru until the end of your current billing period."
    },
    {
      question: "Do you offer refunds?", 
      answer: "We offer a 30-day money-back guarantee. If you're not satisfied with RizzAI Guru within the first 30 days of your paid subscription, we'll refund your payment in full."
    }
  ];

  const platforms = [
    { name: 'Tinder', icon: <TinderIcon fontSize="large" /> },
    { name: 'OkCupid', icon: <OkCupidIcon fontSize="large" /> },
    { name: 'Bumble', icon: <BumbleIcon fontSize="large" /> },
    { name: 'Momo', icon: <MomoIcon fontSize="large" /> },
    { name: 'Hinge', icon: <HingeIcon fontSize="large" /> },
  ];

  return (
    <Box>
      <HeroSection>
        <Container maxWidth="md">
          <Typography variant="h1" component="h1" gutterBottom sx={{ fontWeight: 'bold', fontSize: { xs: '2.5rem', md: '3.5rem' }, color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.3)' }}>
            Unlock Your Best Self with RizzAI Guru
          </Typography>
          <Typography variant="h5" paragraph sx={{ mb: 4, color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,0.2)' }}>
            You've swiped right. You've sent a message. And then... nothing. No reply. No match. With RizzAI Guru, you'll finally break through the noise, create conversations that flow, and make meaningful connections — all powered by cutting-edge AI.
          </Typography>
          <Button 
            variant="contained" 
            size="large" 
            sx={{ 
              mt: 2, 
              bgcolor: 'white', 
              color: '#FF4081', 
              '&:hover': { 
                bgcolor: '#f0f0f0' 
              },
              fontWeight: 'bold',
            }}
            onClick={handleStartTrial}
          >
            Start Your 7-Day Free Trial — Make Your Profile Unstoppable
          </Button>
        </Container>
      </HeroSection>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Popular on the following dating platforms
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 4 }}>
          {platforms.map((platform) => (
            <PlatformIcon key={platform.name}>
              {platform.icon}
              <Typography variant="body2" sx={{ mt: 1 }}>
                {platform.name}
              </Typography>
            </PlatformIcon>
          ))}
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <GradientText variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Why You're Not Getting Matches – And How RizzAI Guru Fixes It
        </GradientText>
        <Typography variant="h5" align="center" paragraph sx={{ mb: 4 }}>
          You could have the perfect smile, the funniest bio, or the most interesting hobbies, but if your profile doesn't stand out in the first three seconds, you're swiping in the dark. Here's how RizzAI Guru can turn your dating game around:
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <FeatureCard>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {React.cloneElement(feature.icon, { fontSize: 'large', color: 'primary', sx: { mr: 1 } })}
                    <Typography variant="h5" component="div">
                      {feature.title}
                    </Typography>
                  </Box>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Why Daters Trust RizzAI Guru
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {['10,000+ Active Users', '93% Match Rate Increase', '4.9/5 User Satisfaction'].map((stat, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h3" color="primary" gutterBottom>
                    {stat.split(' ')[0]}
                  </Typography>
                  <Typography variant="body1">
                    {stat.split(' ').slice(1).join(' ')}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <GradientText variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Real People. Real Connections.
        </GradientText>
        <Typography variant="h5" align="center" paragraph sx={{ mb: 4 }}>
          Join the thousands of daters who have transformed their profiles and conversations with RizzAI Guru. Here's how we helped them find connections that last.
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {[
            {
              name: "John D., 28, New York",
              text: "RizzAI transformed my dating life. The profile optimization suggestions were spot-on, and the conversation starters helped me connect with matches effortlessly. I'm now in a happy relationship thanks to the confidence and skills I gained!"
            },
            {
              name: "Sarah L., 32, Los Angeles",
              text: "I was skeptical at first, but RizzAI Guru exceeded my expectations. The AI-powered suggestions helped me showcase my personality in a way that really resonated with potential matches. I'm having more meaningful conversations and better dates than ever before!"
            },
            {
              name: "Michael T., 35, Chicago",
              text: "As a busy professional, I struggled to find time for dating. RizzAI Guru streamlined the process, helping me create an attractive profile and maintain engaging conversations. It's like having a personal dating assistant – I couldn't be happier with the results!"
            }
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <TestimonialCard>
                <Avatar sx={{ width: 80, height: 80, mb: 2 }}>{item.name[0]}</Avatar>
                <Typography variant="body1" paragraph>
                  "{item.text}"
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  - {item.name}
                </Typography>
              </TestimonialCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <GradientText variant="h4" component="div" gutterBottom>
          Get Unlimited Matches for Just $7/Month
        </GradientText>
        <Typography variant="h5" color="primary" gutterBottom>
          Why spend weeks trying to get noticed when RizzAI Guru can accelerate your results today?
        </Typography>
        <Typography variant="body1" paragraph>
          Our simple, no-fuss plans give you unlimited access to all our features. Get started with:
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Card raised>
              <CardContent>
                <Typography variant="h4" component="div" gutterBottom>
                  Monthly
                </Typography>
                <Typography variant="h3" color="primary" gutterBottom>
                  $7/month
                </Typography>
                <Typography variant="body1" paragraph>
                  • Unlimited AI-generated ice breakers
                  <br />• Unlimited profile optimizations
                  <br />• Unlimited smart reply suggestions
                  <br />• Priority support
                </Typography>
                <Button 
                  variant="contained" 
                  fullWidth 
                  color="primary"
                  size="large"
                  onClick={() => handleSubscription('monthly')}
                >
                  Choose Monthly
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card raised sx={{ bgcolor: '#f0f8ff' }}>
              <CardContent>
                <Typography variant="h4" component="div" gutterBottom>
                  Yearly
                </Typography>
                <Typography variant="h3" color="primary" gutterBottom>
                  $60/year
                </Typography>
                <Typography variant="body1" paragraph>
                  • All Monthly features
                  <br />• Save $24 compared to monthly
                  <br />• Advanced dating analytics
                  <br />• Early access to new features
                </Typography>
                <Button 
                  variant="contained" 
                  fullWidth 
                  color="primary"
                  size="large"
                  onClick={() => handleSubscription('yearly')}
                >
                  Choose Yearly
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Typography variant="h6" align="center" sx={{ mt: 4, fontWeight: 'bold' }}>
          30-Day Money-Back Guarantee
        </Typography>
        <Typography variant="body1" align="center">
          Not satisfied? Get a full refund within 30 days, no questions asked.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          <LockIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body2">
            256-bit SSL Encryption | GDPR Compliant
          </Typography>
        </Box>
      </Container>

      <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
        <Container maxWidth="lg">
          <GradientText variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Frequently Asked Questions
          </GradientText>
          {faqItems.map((item, index) => (
            <FAQCard key={index}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {item.question}
                </Typography>
                <Typography variant="body1">
                  {item.answer}
                </Typography>
              </CardContent>
            </FAQCard>
          ))}
        </Container>
      </Box>

      <Box sx={{ py: 8 }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" paragraph align="center">
            At RizzAI, we believe everyone deserves to find meaningful connections. Our team of dating experts and AI specialists work tirelessly to provide you with the most advanced tools for online dating success. We're committed to helping you navigate the complex world of modern dating, empowering you to build genuine relationships and find lasting happiness.
          </Typography>
        </Container>
      </Box>

      <Box sx={{ bgcolor: '#FF4081', color: 'white', py: 8 }}>
        <Container maxWidth="md">
          <Typography variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Ready to Meet the Right Match?
          </Typography>
          <Typography variant="h5" align="center" paragraph sx={{ mb: 4 }}>
            With RizzAI Guru, you're not just another profile — you're the one they've been waiting to meet. Try it free and start transforming your dating life today.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button 
              variant="contained" 
              size="large" 
              sx={{ bgcolor: 'white', color: '#FF4081', '&:hover': { bgcolor: '#f0f0f0' } }}
              onClick={handleStartTrial}
            >
              Start Your Free Trial Now
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;