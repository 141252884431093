import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Grid, Paper, CircularProgress, Card, CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UpgradePrompt from './UpgradePrompt';

const Home = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCredits();
  }, []);

  const fetchCredits = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/credits`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setCredits(response.data.credits);
    } catch (error) {
      console.error('Error fetching credits:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 8, mb: 6, textAlign: 'center' }}>
        <Typography variant="h1" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#FF4081', fontSize: { xs: '3rem', md: '4rem' } }}>
          Welcome to Rizz App
        </Typography>
        <Typography variant="h5" color="text.secondary" paragraph sx={{ maxWidth: '800px', margin: '0 auto' }}>
          Elevate your dating game with AI-powered icebreakers and profile ratings
        </Typography>
      </Box>

      <Box sx={{ mt: 2, mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: '16px', display: 'inline-block' }}>
          <Typography variant="h6" sx={{ color: credits > 0 ? '#4CAF50' : '#F44336' }}>
            Credits Remaining: {credits}
          </Typography>
        </Paper>
        {credits <= 0 && (
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            <UpgradePrompt onClose={fetchCredits} />
          </Box>
        )}
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {[
          { title: "Generate Ice Breakers", color: "#FF4081", route: "/ice-breaker", description: "Create witty and engaging conversation starters to break the ice and make a lasting impression." },
          { title: "Rate Your Profile", color: "#3F51B5", route: "/profile-rater", description: "Get AI-powered insights on your dating profile photos to maximize your chances of success." },
          { title: "Dating Bio Generator", color: "#009688", route: "/dating-bio-generator", description: "Create an attractive and engaging dating profile bio that stands out." },
          { title: "Reply Enhancer", color: "#FFC107", route: "/reply-enhancer", description: "Improve your responses and keep conversations flowing smoothly." }
        ].map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '16px', overflow: 'hidden' }}>
              <CardContent sx={{ flexGrow: 1, p: 4 }}>
                <Typography variant="h4" component="h2" gutterBottom sx={{ color: item.color }}>
                  {item.title}
                </Typography>
                <Typography variant="body1" paragraph>
                  {item.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ p: 4, pt: 0 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => navigate(item.route)}
                  sx={{ borderRadius: '8px', py: 1.5, bgcolor: item.color, '&:hover': { bgcolor: item.color, opacity: 0.9 } }}
                >
                  {index % 2 === 0 ? 'Start Generating' : 'Get Started'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;