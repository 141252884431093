import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Box, Button, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import api from '../utils/api';
import UpgradePrompt from './UpgradePrompt';

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: '16px',
  padding: theme.spacing(4),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ImageUpload = ({ setExtractedText }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    fetchUserCredits();
  }, []);

  const fetchUserCredits = async () => {
    try {
      const response = await api.get('/api/user/credits');
      setCredits(response.data.credits);
    } catch (err) {
      console.error('Error fetching user credits:', err);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (credits === 0) {
      setError('You have no credits left. Please upgrade to continue.');
      return;
    }

    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('profileImage', selectedFile);

    const token = localStorage.getItem('token');

    try {
      const response = await api.post('/api/upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      
      if (response.data && response.data.extractedText) {
        setExtractedText(response.data.extractedText);
        setCredits(prevCredits => prevCredits - 1);
      } else {
        throw new Error('No extracted text in the response');
      }
    } catch (err) {
      console.error('Full error object:', err);
      if (err.response) {
        setError(`Server error: ${err.response.status} - ${err.response.data.message || 'Unknown error'}`);
      } else if (err.request) {
        setError('No response received from the server. Please try again.');
      } else {
        setError(`Error: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  }, [setExtractedText, credits]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveImage = useCallback((e) => {
    e.stopPropagation();
    setFile(null);
    setPreview(null);
    setExtractedText('');
  }, [setExtractedText]);

  const creditsDisplay = (
    <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 2 }}>
      Credits remaining: {credits === null ? 'Loading...' : credits}
    </Typography>
  );

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Profile Rater
      </Typography>
      {creditsDisplay}
      {credits === 0 ? (
        <UpgradePrompt />
      ) : (
        <>
          <UploadBox {...getRootProps()}>
            <input {...getInputProps()} />
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  {file ? file.name : 'Drag & drop your image here'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  or
                </Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
                  Select File
                </Button>
              </>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </UploadBox>
          {preview && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ position: 'relative', height: 200 }}>
                  <ImagePreview src={preview} alt="Uploaded image" />
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={handleRemoveImage}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    Remove
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ImageUpload;