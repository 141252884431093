import React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText, Paper, IconButton, Snackbar } from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const GenerateIcebreaker = ({ rizzLines }) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true);
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3, borderRadius: '16px' }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Your Rizz Lines:
      </Typography>
      <List>
        {rizzLines.map((line, index) => (
          <ListItem key={index} alignItems="flex-start">
            <ListItemIcon>
              <EmojiEmotionsIcon color="secondary" />
            </ListItemIcon>
            <ListItemText 
              primary={line.replace(/^\d+\.\s*/, '').replace(/^"(.*)"$/, '$1')}
            />
            <IconButton edge="end" aria-label="copy" onClick={() => handleCopy(line)}>
              <ContentCopyIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="Copied to clipboard!"
      />
    </Paper>
  );
};

export default GenerateIcebreaker;