import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import IceBreaker from './components/IceBreaker';
import Auth from './components/Auth';
import AuthCallback from './components/AuthCallback';
import PaymentSuccess from './components/PaymentSuccess';
import ProfileRater from './components/ProfileRater';
import Navbar from './components/Navbar';
import DatingBioGenerator from './components/DatingBioGenerator';
import LandingPage from './components/LandingPage';
import MessageReplyEnhancer from './components/MessageReplyEnhancer';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF4081',
    },
    secondary: {
      main: '#3F51B5',
    },
  },
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const authenticated = !!token;
    console.log('Authentication status:', authenticated);
    setIsAuthenticated(authenticated);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {isAuthenticated && <Navbar />}
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/ice-breaker" element={<IceBreaker />} />
              <Route path="/profile-rater" element={<ProfileRater />} />
              <Route path="/dating-bio-generator" element={<DatingBioGenerator />} />
              <Route path="/message-reply-enhancer" element={<MessageReplyEnhancer />} />
            </>
          ) : (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Auth />} />
              <Route path="/auth/callback" element={<AuthCallback setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
            </>
          )}
          {/* Update these two routes */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="*" element={<Navigate to={isAuthenticated ? "/" : "/login"} replace />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;