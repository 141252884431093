import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Modal, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import MessageReplyEnhancerIcon from '@mui/icons-material/ChatBubbleOutline';

const Navbar = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    console.log('Fetching user profile...');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/profile`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('User profile response:', response.data);
      setUserProfile(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleProfileClick = () => {
    fetchUserProfile();
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Rizz App
          </Typography>
          <Button color="inherit" component={Link} to="/">Home</Button>
          <Button color="inherit" component={Link} to="/ice-breaker">Ice Breaker</Button>
          <Button color="inherit" component={Link} to="/profile-rater">Profile Rater</Button>
          <Button color="inherit" component={Link} to="/dating-bio-generator">Dating Bio Generator</Button>
          <Button color="inherit" component={Link} to="/message-reply-enhancer">
            Reply Enhancer
          </Button>
          <Button color="inherit" onClick={handleProfileClick}>Profile</Button>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>

      <Modal
        open={isProfileModalOpen}
        onClose={handleCloseProfileModal}
        aria-labelledby="profile-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="profile-modal-title" variant="h6" component="h2">
            User Profile
          </Typography>
          {userProfile ? (
            <>
              <Typography>Name: {userProfile.name}</Typography>
              <Typography>Email: {userProfile.email}</Typography>
              <Typography>Credits: {userProfile.credits}</Typography>
            </>
          ) : (
            <Typography>Loading profile...</Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;