import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const PaymentSuccess = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const sessionId = queryParams.get('session_id');

		if (sessionId) {
			const verifyPayment = async () => {
				try {
					const token = localStorage.getItem('token');
					await axios.post(
						`${process.env.REACT_APP_API_URL}/stripe/verify-payment`,
						{ sessionId },
						{
							headers: {
								'Authorization': `Bearer ${token}`,
								'Content-Type': 'application/json'
							}
						}
					);
					// Payment verified successfully, redirect to main page
					navigate('/');
				} catch (error) {
					console.error('Error verifying payment:', error);
					// Handle error (e.g., show error message to user)
				}
			};

			verifyPayment();
		} else {
			// No session ID found, redirect to main page
			navigate('/');
		}
	}, [navigate, location]);

	return (
		<div>
			<h2>Payment Successful!</h2>
			<p>Redirecting you back to the app...</p>
		</div>
	);
};

export default PaymentSuccess;
