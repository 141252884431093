import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ImageUpload from './ImageUpload';
import GenerateIcebreaker from './GenerateIcebreaker';
import UpgradePrompt from './UpgradePrompt';
import axios from 'axios';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  textAlign: 'center',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
}));

const IceBreaker = () => {
  const [extractedText, setExtractedText] = useState('');
  const [rizzLines, setRizzLines] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/credits`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setCredits(response.data.credits);
      } catch (err) {
        console.error('Error fetching credits:', err);
      }
    };
    fetchCredits();
  }, []);

  const handleGenerateClick = async () => {
    if (credits <= 0) {
      setShowUpgradePrompt(true);
      return;
    }

    setLoading(true);
    setError('');
    setRizzLines([]);
    try {
      if (!extractedText) {
        throw new Error('No text extracted from the image.');
      }
      console.log('Sending request with text:', extractedText);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/generate-icebreaker`,
        { extractedText },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log('Received response:', response.data);
      if (Array.isArray(response.data.rizzLine)) {
        setRizzLines(response.data.rizzLine);
        setCredits(prevCredits => prevCredits - 1);
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (err) {
      console.error('Full error object:', err);
      if (err.response && err.response.status === 403) {
        setShowUpgradePrompt(true);
      } else {
        setError(err.response?.data?.message || err.message || 'Failed to generate icebreakers. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom sx={{ mt: 4, fontWeight: 'bold', color: 'primary.main' }}>
        Rizz App
      </Typography>
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Credits remaining: {credits}
        </Typography>
        {credits <= 0 ? (
          <UpgradePrompt />
        ) : (
          <>
            <ImageUpload setExtractedText={setExtractedText} />
            {extractedText && (
              <Box sx={{ mt: 4 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<EmojiEmotionsIcon />}
                  onClick={handleGenerateClick}
                  disabled={loading}
                  sx={{ borderRadius: '25px', padding: '10px 30px' }}
                >
                  {loading ? 'Generating...' : 'Generate Rizz'}
                </Button>
              </Box>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            {rizzLines && Array.isArray(rizzLines) && rizzLines.length > 0 && <GenerateIcebreaker rizzLines={rizzLines} />}
          </>
        )}
      </StyledPaper>
    </Container>
  );
};

export default IceBreaker;