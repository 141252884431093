import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Container, Typography, Button, Box, CircularProgress, Grid, IconButton, Accordion, AccordionSummary, AccordionDetails, Alert } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import UpgradePrompt from './UpgradePrompt';

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: '16px',
  padding: theme.spacing(6),
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(4),
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PreviewContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  marginBottom: '16px',
  border: '2px solid #e0e0e0',
  borderRadius: '10px',
  overflow: 'hidden',
});

const PreviewImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
});

const DeleteButton = styled(IconButton)({
  position: 'absolute',
  top: 5,
  right: 5,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
});

const ProfileRater = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [compressing, setCompressing] = useState(false);
  const [credits, setCredits] = useState(null);

  const fetchUserCredits = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/credits`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setCredits(response.data.credits);
    } catch (error) {
      console.error('Error fetching user credits:', error);
      setCredits(0);
    }
  }, []);

  useEffect(() => {
    fetchUserCredits();
  }, [fetchUserCredits]);

  const compressImage = useCallback(async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (selectedFiles.length + acceptedFiles.length > 10) {
      setError('You can upload a maximum of 10 photos.');
      return;
    }
    setCompressing(true);
    const compressedFiles = await Promise.all(acceptedFiles.map(compressImage));
    setSelectedFiles(prevFiles => [...prevFiles, ...compressedFiles].slice(0, 10));
    setCompressing(false);
  }, [compressImage, selectedFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 10 - selectedFiles.length,
  });

  const handleDelete = useCallback((index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  }, []);

  const handleSubmit = useCallback(async () => {
    if (selectedFiles.length < 3) {
      setError('Please upload at least 3 photos.');
      return;
    }
    if (credits !== 'unlimited' && credits <= 0) {
      setError('You do not have enough credits to rate your profile. Please upgrade.');
      return;
    }
    setLoading(true);
    setError('');
    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append('images', file));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/profile-rater/rate-profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setFeedback(response.data.feedback);
      fetchUserCredits();
    } catch (error) {
      console.error('Error rating profile:', error);
      setError('Failed to rate profile. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedFiles, credits, fetchUserCredits]);

  const uploadBoxStyle = useMemo(() => ({
    backgroundColor: isDragActive ? 'rgba(0, 0, 0, 0.1)' : 'transparent'
  }), [isDragActive]);

  const renderImagePreviews = useMemo(() => (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      {selectedFiles.map((file, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <PreviewContainer>
            <PreviewImage src={URL.createObjectURL(file)} alt={`Preview ${index + 1}`} />
            <DeleteButton size="small" onClick={() => handleDelete(index)}>
              <DeleteIcon />
            </DeleteButton>
          </PreviewContainer>
        </Grid>
      ))}
    </Grid>
  ), [selectedFiles, handleDelete]);

  const renderFeedback = useMemo(() => {
    if (!feedback) return null;

    const feedbackSections = feedback.split('Image').filter(f => f.trim()) || [];
    const overallSuggestions = feedback.split('Overall Profile Suggestions:')[1]?.trim() || '';

    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Profile Feedback
        </Typography>
        {feedbackSections.map((section, index) => {
          const [, ...contentLines] = section.split('\n');
          const content = contentLines.join('\n');
          const rating = content.match(/Rating: (\d+)\/10/)?.[1] || '0';
          const strengths = content.match(/Strengths:([\s\S]*?)Areas for Improvement:/)?.[1]?.split('-').filter(s => s.trim()) || [];
          const improvements = content.match(/Areas for Improvement:([\s\S]*?)Rating:/)?.[1]?.split('-').filter(s => s.trim()) || [];

          return (
            <Accordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.primary.main,
                  color: 'white',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>Image {index + 1}</Typography>
                  <Box sx={{ 
                    backgroundColor: 'white', 
                    color: 'primary.main', 
                    borderRadius: '50%', 
                    width: 40, 
                    height: 40, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    fontWeight: 'bold'
                  }}>
                    {rating}/10
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Strengths:
                  </Typography>
                  <ul style={{ paddingLeft: '20px' }}>
                    {strengths.map((strength, i) => (
                      <li key={i}><Typography>{strength.trim()}</Typography></li>
                    ))}
                  </ul>
                </Box>
                <Box>
                  <Typography variant="subtitle1" color="secondary" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Areas for Improvement:
                  </Typography>
                  <ul style={{ paddingLeft: '20px' }}>
                    {improvements.map((improvement, i) => (
                      <li key={i}><Typography>{improvement.trim()}</Typography></li>
                    ))}
                  </ul>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
        {overallSuggestions && (
          <Accordion>
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: theme => theme.palette.secondary.main,
                color: 'white',
              }}
            >
              <Typography variant="h6">Overall Profile Suggestions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div">
                <ul style={{ paddingLeft: '20px' }}>
                  {overallSuggestions.split('-').filter(s => s.trim()).map((suggestion, index) => (
                    <li key={index}><Typography>{suggestion.trim()}</Typography></li>
                  ))}
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    );
  }, [feedback]);

  return (
    <Container maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom sx={{ mt: 4, mb: 4, fontWeight: 'bold' }}>
        Profile Rater
      </Typography>
      <Typography variant="body2" align="right" sx={{ mt: 2, mb: 2 }}>
        Available Credits: {credits !== null ? (credits === 'unlimited' ? 'Unlimited' : credits) : 'Loading...'}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Upload your photos and we'll help you choose the best ones for your dating profile.
      </Typography>
      {credits !== 'unlimited' && credits === 0 && <UpgradePrompt onClose={() => {/* Add your logic here */}} />}
      <Typography variant="body1" align="center" gutterBottom>
        Please upload at least 3 photos and a maximum of 10 photos.
      </Typography>
      <UploadBox {...getRootProps()} style={uploadBoxStyle}>
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          Drag & drop your images here
        </Typography>
        <Typography variant="body2" color="textSecondary">
          or
        </Typography>
        <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
          Select Files
        </Button>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          {compressing ? 'Compressing images...' : `${selectedFiles.length}/10 images uploaded`}
        </Typography>
      </UploadBox>
      {selectedFiles.length > 0 && renderImagePreviews}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={selectedFiles.length < 3 || loading}
        sx={{ mt: 4, display: 'block', margin: '0 auto', px: 4, py: 1 }}
      >
        Rate Profile
      </Button>
      {loading && (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <CircularProgress sx={{ mb: 2 }} />
          <Typography variant="h6" gutterBottom>
            Rating Your Profile...
          </Typography>
          <Typography variant="body1" color="textSecondary">
            We're carefully analyzing your images to help you create the best dating profile possible. This may take a moment, but it's worth the wait for the best looking dating profile.
          </Typography>
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      {feedback && renderFeedback}
    </Container>
  );
};

export default ProfileRater;