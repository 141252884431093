import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const UpgradePrompt = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleUpgrade = async () => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      const { sessionId } = response.data;
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        setError(error.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to create checkout session');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 4, p: 3, border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h5" gutterBottom>
        Upgrade to Unlimited Credits!
      </Typography>
      <Typography variant="body1" paragraph>
        You've used all your free rizz lines. Upgrade now to generate unlimited icebreakers for just $7.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpgrade}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? 'Processing...' : 'Upgrade for $7'}
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default UpgradePrompt;