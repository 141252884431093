import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthCallback = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    console.log('AuthCallback: Token received:', token);
    console.log('AuthCallback: Full URL:', window.location.href);

    if (token) {
      localStorage.setItem('token', token);
      console.log('AuthCallback: Token stored in localStorage');
      setIsAuthenticated(true);
      console.log('AuthCallback: setIsAuthenticated called with true');
      navigate('/');
    } else {
      console.log('AuthCallback: No token found, redirecting to login');
      navigate('/login');
    }
  }, [location, navigate, setIsAuthenticated]);

  return <div>Processing authentication...</div>;
};

export default AuthCallback;