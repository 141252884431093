import React from 'react';
import { Typography, Container, Box } from '@mui/material';


const TermsOfService = () => {
  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Terms of Service
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Effective Date: September 16, 2024
          </Typography>

          <Typography paragraph>
            Welcome to RizzAI Guru! These Terms of Service ("Terms") govern your use of our website www.rizzai.guru and the services provided by RizzAI Guru (collectively, the "Service").
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use the Service.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            2. Eligibility
          </Typography>
          <Typography paragraph>
            You must be at least 18 years old to use the Service. By using the Service, you represent and warrant that you meet this age requirement.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            3. Account Registration
          </Typography>
          <Typography paragraph>
            To use certain features of the Service, you may need to create an account. You agree to provide accurate, current, and complete information when creating your account and to keep your login credentials secure. You are responsible for any activity under your account.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            4. Free Credits and Paid Services
          </Typography>
          <Typography paragraph>
            RizzAI Guru offers a free tier with limited credits, as well as paid services for extended or premium features. All payments are processed via Stripe, and you agree to our pricing and payment terms when purchasing services.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            5. User Conduct
          </Typography>
          <Typography paragraph>
            You agree not to use the Service to:
          </Typography>
          <Typography component="ul">
            <li>Engage in any illegal or unauthorized activities.</li>
            <li>Distribute any content that is unlawful, harassing, abusive, or harmful.</li>
            <li>Interfere with the security or performance of the Service.</li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            6. Intellectual Property
          </Typography>
          <Typography paragraph>
            All content, trademarks, and intellectual property related to the Service are owned by RizzAI Guru or our licensors. You may not use or reproduce any part of the Service without our express written permission.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            7. Termination
          </Typography>
          <Typography paragraph>
            We reserve the right to suspend or terminate your account at our discretion, including if you violate these Terms.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            8. Disclaimers
          </Typography>
          <Typography paragraph>
            The Service is provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the performance or availability of the Service.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            9. Limitation of Liability
          </Typography>
          <Typography paragraph>
            To the fullest extent permitted by law, RizzAI Guru shall not be liable for any indirect, incidental, or consequential damages arising out of your use of the Service.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            10. Governing Law
          </Typography>
          <Typography paragraph>
            These Terms will be governed by and construed in accordance with the laws of [insert jurisdiction].
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            11. Changes to Terms
          </Typography>
          <Typography paragraph>
            We may modify these Terms at any time. If we do, we will post the updated Terms on our website and notify you of any material changes.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            12. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about these Terms, please contact us.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default TermsOfService;