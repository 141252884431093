import React from 'react';
import { Button, Container, Typography, Box, Card, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  margin: '0 auto',
  marginTop: theme.spacing(8),
  padding: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
}));

const Logo = styled('div')(({ theme }) => ({
  width: 100,
  height: 100,
  margin: '0 auto',
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '2rem',
  fontWeight: 'bold',
}));

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#757575',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,.25)',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  paddingLeft: '50px',
  position: 'relative',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
}));

const GoogleLogo = styled('img')({
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '18px',
  height: '18px',
});

const Auth = () => {
  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
    console.log('Redirecting to Google login...', process.env.REACT_APP_API_URL);
  };

  return (
    <Container maxWidth="sm">
      <StyledCard>
        <CardContent>
          <Logo>RA</Logo>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Welcome to RizzAI
          </Typography>
          <Typography variant="body1" align="center" color="textSecondary" paragraph>
            Sign in to access your account and start enhancing your dating game.
          </Typography>
          <Divider sx={{ my: 3 }} />
          <GoogleButton
            fullWidth
            variant="contained"
            onClick={handleGoogleLogin}
          >
            <GoogleLogo src="https://developers.google.com/identity/images/g-logo.png" alt="Google logo" />
            Sign in with Google
          </GoogleButton>
          <Box mt={3}>
            <Typography variant="body2" color="textSecondary" align="center">
              By signing in, you agree to our{' '}
              <a href="/terms-of-service" style={{ color: 'inherit' }}>Terms of Service</a>
              {' '}and{' '}
              <a href="/privacy-policy" style={{ color: 'inherit' }}>Privacy Policy</a>.
            </Typography>
          </Box>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default Auth;